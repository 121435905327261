<template>
  <div class="menu">
    <div class="menu_content">
      <nuxt-link class="menu__item" to="/mon-vehicule">
        <icon-vehicule
          :stroke="isActive('/mon-vehicule') ? 'var(--theme-identite)' : 'var(--theme-icone)'"
          filled
          style="font-size: 30px"
          class="menu__icon"
        />
        <span class="menu_item_info">Véhicule</span>
      </nuxt-link>
      <nuxt-link
        v-if="vehiculeStore.isServiceAutorise(ServiceCopilot.TROUVER_PRESTATAIRE)"
        class="menu__item"
        to="/recherche-prestataire/recherche"
      >
        <icon-pin
          :fill="isActive('/recherche-prestataire/recherche') ? 'var(--theme-identite)' : 'var(--theme-icone)'"
          filled
          style="font-size: 30px"
          class="menu__icon"
        />
        <span class="menu_item_info">Garages</span>
      </nuxt-link>
      <nuxt-link
        v-if="vehiculeStore.isServiceAutorise(ServiceCopilot.ETAT_DES_LIEUX_VEHICULE)"
        class="menu__item"
        to="/etat-des-lieux"
      >
        <icon-appareil-photo
          :fill="isActive('/etat-des-lieux') ? 'var(--theme-identite)' : 'var(--theme-icone)'"
          filled
          style="font-size: 30px"
          class="menu__icon"
        />
        <span class="menu_item_info">État des lieux</span>
      </nuxt-link>
      <nuxt-link
        v-if="vehiculeStore.isServiceAutorise(ServiceCopilot.REPARATION_CARROSSERIE)"
        class="menu__item"
        to="/reparation-carrosserie"
      >
        <icon-carrosserie
          :fill="isActive('/reparation-carrosserie') ? 'var(--theme-identite)' : 'var(--theme-icone)'"
          filled
          style="font-size: 30px"
          class="menu__icon"
        />
        <span class="menu_item_info">Réparation Carrosserie</span>
      </nuxt-link>

      <nuxt-link class="menu__item" to="/que-faire">
        <icon-point-interrogation
          :fill="isActive('/que-faire') ? 'var(--theme-identite)' : 'var(--theme-icone)'"
          filled
          style="font-size: 30px"
          class="menu__icon"
        />
        <span class="menu_item_info">Aide</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const vehiculeStore = useVehiculeStore();

const isActive = (route: string) => {
  return route === useRoute().path;
};
</script>
<style scoped>
.menu {
  background: var(--theme-fond-1);
  box-shadow: 0px -10px 30px 10px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
}

.menu_content {
  width: 100%;
  max-width: 700px;
  padding: 0 5px;
  display: flex;
  justify-content: space-around;
  margin: auto;
}

.menu__item {
  color: var(--grey300);
  fill: var(--grey300);
  cursor: pointer;
  padding: 0 16px;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.menu_item_info {
  font-size: 12px;
  margin: auto 0;
  color: var(--theme-icone);
  text-align: center;
}

.router-link-active .stroke .fill .svg-icon:deep(svg) {
  color: var(--theme-identite) !important;
  stroke: var(--theme-identite) !important;
}

.router-link-active .fill .svg-icon:deep(svg) {
  color: var(--theme-identite) !important;
  fill: var(--theme-identite) !important;
}

.router-link-active {
  color: var(--theme-identite) !important;
  fill: var(--theme-identite);
}

.router-link-active .menu_item_info {
  color: var(--theme-identite) !important;
  fill: var(--theme-identite) !important;
}

@media screen and (max-width: 600px) {
  .menu_item_info {
    font-size: 10px;
  }

  .menu__item {
    padding: 0;
  }

  .menu__icon:deep(svg),
  .menu__icon {
    width: 22px !important;
    height: 22px !important;
  }
}

@media screen and (max-height: 700px) and (min-width: 450px) {
  .menu__icon {
    width: 25px;
    height: 25px;
  }
}
</style>
